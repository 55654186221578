<template>
  <div class="academic-staff__profile-form mb-16" data-test="profile-container">
    <!-- TODO: https://careerpartner.atlassian.net/browse/EP-3478 -->
    <validation-form
      v-slot="{ meta }"
      ref="observer"
      :initial-values="initialValues"
    >
      <n-toolbar>
        <n-button
          :disabled="
            disableSafeButton || !meta.valid || !meta.dirty || !hasChanged
          "
          variant="quaternary"
          data-test="profile-submit"
          @click="onSubmit"
        >
          <n-icon icon="mdi-content-save-outline" class="mr-2" />
          {{ $t('common.labels.save') }}
        </n-button>
        <n-button
          v-if="profile.id"
          variant="quaternary"
          data-test="profile-toggle-planning-status"
          @click="onTogglePlanningStatus"
        >
          <n-icon
            :icon="
              profile.planningStatus
                ? 'mdi-account-cancel-outline'
                : 'mdi-account-check-outline'
            "
            class="mr-2"
          />
          {{
            profile.planningStatus
              ? $t('views.profiles.profile-form.deactivate-planning')
              : $t('views.profiles.profile-form.activate-planning')
          }}
        </n-button>
        <SoftDeleteButton
          v-if="profile.id"
          :is-soft-deleted="isProfileSoftDeleted"
          @soft-delete="$emit('softDelete')"
          @restore="$emit('restore')"
        />
      </n-toolbar>
      <error-banner />
      <div class="xl:grid xl:grid-cols-3">
        <div class="col-span-2">
          <form
            id="profile-form"
            data-test="profile-form"
            @submit.prevent="onSubmit"
          >
            <general v-model="profile" />
            <personal v-model="profile" :disabled="disabled" />
          </form>
          <slot name="contacts" />
        </div>
      </div>
    </validation-form>
  </div>
</template>

<script>
import General from '@/components/profiles/General';
import Personal from '@/components/profiles/Personal';
import ErrorBanner from '@/components/common/ErrorBanner';
import _ from '@/plugins/lodash';
import SoftDeleteButton from '@/components/profiles/SoftDeleteButton.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('puffer');
export default {
  name: 'ProfileForm',
  components: {
    SoftDeleteButton,
    ErrorBanner,
    General,
    Personal,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    disableSafeButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit', 'togglePlanningStatus', 'softDelete', 'restore'],
  data() {
    return {
      profile: _.cloneDeep(this.modelValue),
      image: '',
    };
  },
  computed: {
    ...mapGetters(['isProfileSoftDeleted']),
    hasChanged() {
      return !_.isEqual(this.profile, this.modelValue);
    },
    initialValues() {
      return {
        title: this.profile.honorificPrefix,
        degree: this.profile.degree,
        citizenships: this.profile.citizenships,
        givenName: this.profile.givenName,
        familyName: this.profile.familyName,
        dateOfBirth: this.profile.dateOfBirth,
        gender: this.profile.gender,
        brand: this.profile.brand,
        teachingLevel: this.profile.teachingLevel,
      };
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function (newVal) {
        this.profile.planningStatus = newVal.planningStatus;
        this.profile.academyId = newVal.academyId;
      },
    },
  },
  methods: {
    // TODO: https://careerpartner.atlassian.net/browse/EP-3478
    async onSubmit() {
      const validForm = await this.$refs.observer.validate();

      if (!validForm) {
        return;
      }

      this.$emit('submit', this.profile);
    },

    async onTogglePlanningStatus() {
      this.$emit('togglePlanningStatus');
    },
  },
};
</script>

<template>
  <view-wrapper>
    <template #headline>{{ $t('views.profiles-create.headline') }}</template>
    <profile-form
      v-model="initialValue"
      data-test="create-profile-form"
      :disable-safe-button="isSubmitting || !emails.length"
      @submit="createProfile"
    >
      <template #contacts>
        <profile-contacts
          :emails="emails"
          :addresses="addresses"
          :phones="phones"
          @create-contact="createContact"
          @update-contact="updateContact"
          @delete-contact="deleteContact"
          @set-as-primary-contact="setAsPrimaryContact"
          @open-contact-create="goToCreate"
          @open-contact-update="goToUpdate"
          @open-contact-delete="goToDelete"
          @close="closeModals"
        />
      </template>
    </profile-form>
    <n-modal
      ref="modal"
      :is-open="$route.meta.showModal"
      :title="$route.meta.modalTitle"
      size="lg"
      display-close-button
      white
      auto-height
      @close="closeModals"
    >
      <router-view name="modal" />
    </n-modal>
    <confirmation-modal
      :is-open="$route.meta.showDeletionModal"
      :subject="`Bist Du sicher, dass Du diese ${resourceNameDisplayName} löschen möchtest?`"
      :description="`Diese ${resourceNameDisplayName} wird archiviert und kann nur mit Admin Rechten wiederhergestellt werden.`"
      :extra-data="dataToDelete"
      @close="closeModals"
      @confirm="deleteContact"
    />
  </view-wrapper>
</template>

<script>
import { Api } from '@/api';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import ProfileContacts from '@/components/profiles/ProfileContacts';
import ProfileForm from '@/components/profiles/ProfileForm';
import { mapActions, mapGetters } from 'vuex';
import ViewWrapper from '@/components/common/ViewWrapper.vue';
export default {
  name: 'CreateProfile',
  components: {
    ViewWrapper,
    ConfirmationModal,
    ProfileContacts,
    ProfileForm,
  },
  data() {
    return {
      isSubmitting: false,
      initialValue: {
        citizenships: [],
        dateOfBirth: null,
        degree: null,
        employmentType: '',
        academyId: '',
        familyName: '',
        gender: '',
        givenName: '',
        honorificPrefix: null,
        brand: 'iu',
      },
      addresses: [],
      emails: [],
      phones: [],
      dataToDelete: '',
      currentResourceName: null,
      currentContactId: null,
    };
  },
  computed: {
    ...mapGetters('profile', ['getContact']),
    resourceNameDisplayName() {
      switch (this.$route.meta.resourceName) {
        case 'emails':
          return 'E-Mail';
        case 'addresses':
          return 'Adresse';
        case 'phones':
          return 'Telefonnummer';
      }
      return '';
    },
  },
  watch: {
    '$route.meta.resourceName': {
      async handler(value) {
        if (value) {
          this.currentResourceName = value;
          const currentContact = await this.getContact(
            this.$route.params.contactId
          );
          if (currentContact) {
            this.currentContactId = this.$route.params.contactId;
            switch (value) {
              case 'emails':
                this.dataToDelete = currentContact.email;
                break;
              case 'addresses':
                this.dataToDelete = `${currentContact.street} ${currentContact.houseNumber}
          ${currentContact.zip} ${currentContact.city}
          ${currentContact.country}`;
                break;
              case 'phones':
                this.dataToDelete = currentContact.phonenumber;
            }
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      updateSnackbar: 'snackbar/update',
      insertContactIntoStore: 'profile/insertContact',
      updateContactInStore: 'profile/updateContact',
      deleteContactFromStore: 'profile/deleteContact',
    }),
    setContacts({ addresses, emails, phones }) {
      addresses && (this.addresses = addresses);
      emails && (this.emails = emails);
      phones && (this.phones = phones);
    },
    closeModals() {
      this.$router.push({ name: 'profile-create' });
    },
    goToCreate(resourceName) {
      this.$router.push({ name: `profile-create.${resourceName}.create` });
    },
    goToUpdate(resourceName, contact) {
      this.$router.push({
        name: `profile-create.${resourceName}.create-update`,
        query: { contactId: contact.id },
      });
    },
    goToDelete(resourceName, contact) {
      this.$router.push({
        name: `profile-create.${resourceName}.create-delete`,
        query: { contactId: contact.id },
      });
    },
    async createContact(resourceName, payload) {
      const _items = [
        ...this[resourceName],
        { ...payload, id: Math.random().toString().split('.')[1] },
      ];

      this.setContacts({ [resourceName]: _items });
      await this.insertContactIntoStore(_items[_items.length - 1]);
      this.closeModals();
    },
    async updateContact(resourceName, payload) {
      const _items = this[resourceName].map((item) =>
        item.id === payload.id ? payload : item
      );

      this.setContacts({ [resourceName]: _items });
      await this.updateContactInStore(payload);
      this.closeModals();
    },
    async deleteContact() {
      const _items = this[this.currentResourceName].filter(
        (item) => item.id !== this.currentContactId
      );

      this.setContacts({ [this.currentResourceName]: _items });
      await this.deleteContactFromStore(this.currentContactId);
      this.currentResourceName = null;
      this.currentContactId = null;
      this.closeModals();
    },
    async setAsPrimaryContact(resourceName, payload) {
      const _items = this[resourceName].map((item) => ({
        ...item,
        primary: item === payload,
      }));

      this.setContacts({ [resourceName]: _items });
    },
    async createProfile(profile) {
      this.isSubmitting = true;

      const payload = {
        ...profile,
        addresses: this.addresses,
        emails: this.emails,
        phones: this.phones,
      };

      try {
        const { data } = await Api.profiles.post(payload);

        await this.updateSnackbar({ message: 'Profile has been created' });

        await this.$router.push({
          name: 'profile-update',
          params: { profileId: data.id },
        });
      } catch (e) {
        // do nothing
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<template>
  <section-structure>
    <template #headline>
      <div class="flex justify-between">
        <h3 class="font-bold uppercase text-blue-600 text-xl">
          {{ $t('views.profiles-general.headline') }}
        </h3>
        <div v-if="profile.academyId && careBaseUrl" class="col-span-6 flex">
          <a
            :href="careProfileDeeplink"
            data-test="profile-form__link-to-care"
            target="_blank"
            class="flex items-center font-bold"
          >
            {{ $t('views.profiles-general.care-profile') }}
            <n-icon icon="open-in-new" class="ml-1" />
          </a>
        </div>
      </div>
    </template>
    <template #default>
      <h4 class="col-span-6 font-bold uppercase text-blue-600 text-base mt-3">
        {{ $t('views.profiles-general.subheadline') }}
      </h4>
      <div class="col-span-3 relative">
        <n-input
          id="profile-form__external-id"
          v-model="profile.academyId"
          disabled
          :label="$t('common.labels.academy-id')"
        />
        <loading-spinner
          v-if="!profile.academyId && profile.id"
          :spinner-text="spinnerText"
        />
      </div>
      <div class="col-span-3">
        <validation-provider
          v-slot="{ errors, field }"
          :rules="{ required: !profile.id }"
          name="brand"
          :label="$t('common.labels.brand')"
        >
          <n-select
            id="profile-form__brand"
            v-model="profile.brand"
            v-bind="field"
            track-by="value"
            class="w-full"
            option-label="label"
            mapped
            required
            :disabled="!!profile.id"
            :errors="errors"
            :options="brandOptions"
            :label="$t('common.labels.brand')"
            data-test="profile-form-brand"
          />
        </validation-provider>
      </div>
      <div class="col-span-3">
        <validation-provider
          v-slot="{ errors, field }"
          :rules="{ required: true }"
          name="teachingLevel"
          :label="$t('common.labels.teaching-level')"
        >
          <n-select
            id="profile-form__teachinglevel"
            v-model="profile.teachingLevel"
            v-bind="field"
            class="w-full"
            mapped
            required
            :label="$t('common.labels.teaching-level')"
            :errors="errors"
            :options="teachingLevels"
            data-test="profile-form-teachinglevel"
          />
        </validation-provider>
      </div>
      <div class="col-span-3">
        <validation-provider
          v-slot="{ errors, field }"
          name="employmentType"
          :label="$t('common.labels.employment-type')"
        >
          <n-select
            id="profile-form__employmentType"
            v-model="profile.employmentType"
            name="employmentType"
            v-bind="field"
            class="w-full"
            track-by="value"
            option-label="label"
            mapped
            :label="$t('common.labels.employment-type')"
            :errors="errors"
            :options="employmentTypeOptions"
            data-test="profile-form-employment-type"
          />
        </validation-provider>
      </div>
      <div class="col-span-3">
        <validation-provider
          v-slot="{ errors, field }"
          name="contractStartDate"
          :label="$t('common.labels.contract-start-date')"
          rules="requiredIfTargetFilled:@contractEndDate"
        >
          <n-input
            id="profile-form__contract-start-date"
            :model-value="profile.contractStartDate"
            name="contractStartDate"
            :errors="errors"
            v-bind="field"
            type="date"
            :label="$t('common.labels.contract-start-date')"
            :required="!!profile.contractEndDate"
            @update:model-value="updateStartDateValue"
          />
        </validation-provider>
      </div>
      <div class="col-span-3">
        <validation-provider
          v-slot="{ errors, field }"
          name="contractEndDate"
          :label="$t('common.labels.contract-end-date')"
          rules="emptyOrAfter:@contractStartDate"
        >
          <n-input
            id="profile-form__contract-end-date"
            :model-value="profile.contractEndDate"
            name="contractEndDate"
            :errors="errors"
            v-bind="field"
            type="date"
            :label="$t('common.labels.contract-end-date')"
            :min="getEndDateMinimumDate"
            @update:model-value="updateEndDateValue"
          />
        </validation-provider>
      </div>
      <div class="col-span-6">
        <validation-provider
          v-slot="{ field }"
          name="overallCapacity"
          :label="$t('common.labels.overall-deputation')"
        >
          <capacity-input
            :model-value="profile.overallCapacity"
            :field="field"
            :label="$t('common.labels.overall-deputation')"
            @update:model-value="updateOverallCapacity"
          ></capacity-input>
        </validation-provider>
      </div>
    </template>
  </section-structure>
</template>

<script>
import { t } from '@/plugins/translations';
import { mapState } from 'vuex';
import SectionStructure from '@/components/common/SectionStructure';
import { sanitizeUrl } from '@braintree/sanitize-url';
import urlJoin from 'url-join';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import { BRANDS } from '@/constants/picklists/brands';
import CapacityInput from '@/components/profiles/CapacityInput.vue';

export default {
  name: 'General',
  components: { CapacityInput, LoadingSpinner, SectionStructure },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profile: this.modelValue,
      spinnerText: 'Das Profil wird derzeit mit Care abgeglichen.',
      employmentTypeOptions: [
        {
          label: t('common.enums.employment-type.external'),
          value: 'external',
        },
        {
          label: t('common.enums.employment-type.internal'),
          value: 'internal',
        },
      ],
      brandOptions: BRANDS,
    };
  },
  computed: {
    ...mapState('init', ['teachingLevels']),
    careBaseUrl() {
      return SECRETS.CARE_BASE_URL;
    },
    careProfileDeeplink() {
      return sanitizeUrl(
        urlJoin(
          this.careBaseUrl,
          `/home#/admin/profil.php?lang=de&id=${this.profile.academyId}`
        )
      );
    },
    getEndDateMinimumDate() {
      return this.profile.contractStartDate
        ? new Date(this.profile.contractStartDate).toISOString().split('T')[0]
        : null;
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function (newVal) {
        this.profile.planningStatus = newVal.planningStatus;
        this.profile.academyId = newVal.academyId;
        this.profile.brand = newVal.brand;
        this.profile.teachingLevel = newVal.teachingLevel;
      },
    },
  },
  methods: {
    updateStartDateValue(value) {
      this.profile.contractStartDate = value ? value : null;
    },
    updateEndDateValue(value) {
      this.profile.contractEndDate = value ? value : null;
    },
    updateOverallCapacity(value) {
      this.profile.overallCapacity = value;
    },
  },
};
</script>

<template>
  <td class="px-3 cursor-pointer" @click="linkToProfileEdit(profile.id)">
    {{ profile.honorificPrefix }} {{ profile.givenName }}
    {{ profile.familyName }}
  </td>
  <td class="px-3 cursor-pointer" @click="linkToProfileEdit(profile.id)">
    {{ profile.teachingLevel || '-' }}
  </td>
  <td class="px-3 cursor-pointer" @click="linkToProfileEdit(profile.id)">
    {{
      profile.employmentType
        ? $t(`common.enums.employment-type.${profile.employmentType}`)
        : '-'
    }}
  </td>
  <td class="px-3 cursor-pointer" @click="linkToProfileEdit(profile.id)">
    {{ profile.brand?.toUpperCase() || '-' }}
  </td>
  <td class="px-3 cursor-pointer" @click="linkToProfileEdit(profile.id)">
    {{ profile.units || '-' }}
  </td>
  <td class="px-3 cursor-pointer" @click="linkToProfileEdit(profile.id)">
    {{
      profile.planningStatus === 1
        ? $t('common.enums.planning-status.active')
        : $t('common.enums.planning-status.inactive')
    }}
  </td>
  <td class="px-3 cursor-pointer" @click="linkToProfileEdit(profile.id)">
    {{
      profile.preferenceComplete === 1
        ? $t('common.enums.preference-state.complete')
        : $t('common.enums.preference-state.incomplete')
    }}
  </td>
</template>
<script>
export default {
  name: 'ProfilesListTableData',
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  methods: {
    linkToProfileEdit(profileId) {
      this.$router.push({
        name: 'profile-update',
        params: { profileId },
      });
    },
  },
};
</script>

<template>
  <view-wrapper>
    <n-toolbar>
      <n-button
        variant="quaternary"
        data-test="availability-preferences-submit"
        :disabled="!hasChanges"
        @click="updatePreference"
      >
        <n-icon icon="mdi-content-save-outline" class="mr-2" />
        {{ $t('common.labels.save') }}
      </n-button>
    </n-toolbar>
    <div class="text-blue-600 text-2xl mb-4 font-bold mt-10 uppercase">
      {{ $t('navigation.tabs.availability') }}
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="text-blue-600 text-base"
      v-html="$t('views.availability-preferences.sub-headline')"
    ></div>
    <!-- eslint-enable vue/no-v-html -->
    <div class="flex items-center mt-6 mb-10">
      <template v-if="lastUpdated">
        <icon-with-rounded-background icon="mdi-clock-outline" class="mr-2" />
        <p class="text-blue-600">
          {{ $t('views.availability-preferences.last-edited-at') }}
          <span class="font-bold">
            <a
              v-if="lastUpdated.updatedBy"
              :href="`/services/iam/users/${profileId}/profile`"
            >
              {{ lastUpdated.updatedBy }}
            </a>
            <template v-else>
              {{
                $t(
                  'views.course-capabilities.common.course-capabilities-preferences-last-edited.unknown'
                )
              }}
            </template>
          </span>
          {{ $t('common.labels.at') }}
          <span class="font-bold">{{ lastUpdated.updatedAt }}</span>
        </p>
      </template>
      <template v-else>
        <icon-with-rounded-background icon="cancel" class="mr-2" />
        <span>{{ $t('views.availability-preferences.not-set') }}</span>
      </template>
    </div>
    <n-table :header-items="HEADERS" :data="data" :is-loading="isLoading">
      <template #th-slots="{ item }">
        <span class="text-blue-600">{{ item.label }}</span>
      </template>
      <template #row="{ item }">
        <n-table-row>
          <td class="font-bold text-blue-600 text-center">{{ item.slot }}</td>
          <td
            v-for="weekDay in WEEKDAYS"
            :key="weekDay.key"
            class="font-bold text-center"
          >
            <chip-dropdown
              v-model="item[weekDay.key].preference"
              :options="PREFERENCE_OPTIONS"
              @update:model-value="hasChanges = true"
            />
          </td>
        </n-table-row>
      </template>
    </n-table>
    <unsaved-changes-modal
      :has-changes="hasChanges"
      :is-open="isUnsavedChangesModalOpen"
      @close-and-save="saveAndLeave"
      @leave-site="next"
    />
  </view-wrapper>
</template>

<script>
import ViewWrapper from '@/components/common/ViewWrapper.vue';
import { formatTimeStampToLocale } from '@/utils/formatters';
import {
  WEEKDAYS,
  COLORS,
  HEADERS,
} from '@/constants/availability-preferences.constants';
import ChipDropdown from '@/components/common/ChipDropdown.vue';
import { availabilityPreferenceApi } from '@/api/availability-preference';
import { iamApi } from '@/api/iam';
import { mapActions } from 'vuex';
import UnsavedChangesModal from '@/components/common/UnsavedChangesModal.vue';
import { PREFERENCES } from '@/constants/picklists/preferences';
import IconWithRoundedBackground from '@/components/common/IconWithRoundedBackground.vue';

export default {
  name: 'AvailabilityPreferences',
  components: {
    IconWithRoundedBackground,
    ChipDropdown,
    ViewWrapper,
    UnsavedChangesModal,
  },
  beforeRouteLeave(to, from, next) {
    this.next = next;
    if (this.hasChanges) {
      this.isUnsavedChangesModalOpen = true;
    } else {
      next();
    }
  },
  props: {
    profileId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      isLoading: false,
      data: [],
      WEEKDAYS,
      PREFERENCE_OPTIONS: Object.values(PREFERENCES).filter(
        (preference) => preference.value !== 'UNKNOWN'
      ),
      HEADERS,
      lastUpdated: null,
      hasChanges: false,
      isUnsavedChangesModalOpen: false,
      next: () => {},
    };
  },
  async beforeMount() {
    await this.loadData();
  },
  methods: {
    ...mapActions({
      updateSnackbar: 'snackbar/update',
    }),
    async loadData() {
      this.data = [];
      this.isLoading = true;
      const slots = await availabilityPreferenceApi.getAvailabilitySlots();
      const preferences =
        await availabilityPreferenceApi.getAvailabilityPreferences(
          this.profileId
        );

      await this.setLastUpdated(preferences);

      for (const slot of slots) {
        const prefsForSlot = preferences.filter(
          (preference) => preference.slotId === slot.id
        );

        const prefRow = {
          slot: `${slot.startTime} - ${slot.endTime}`,
          slotId: slot.id,
        };

        for (const weekDay of WEEKDAYS) {
          const pref = prefsForSlot.find(
            (pref) => pref.weekDay === weekDay.key
          );

          const prefOption = PREFERENCES[pref?.preference || 'NEUTRAL'];

          prefRow[weekDay.key] = {
            chipColor: COLORS[prefOption.value],
            preference: prefOption,
            id: pref?.id || null,
          };
        }

        this.data.push(prefRow);
      }

      this.isLoading = false;
    },
    async updatePreference() {
      this.hasChanges = false;
      this.isLoading = true;

      const bulkPreferences = [];

      for (const item of this.data) {
        for (const weekDay of WEEKDAYS) {
          const pref = item[weekDay.key];
          bulkPreferences.push({
            profileId: this.profileId,
            preference: pref.preference.value,
            slotId: item.slotId,
            weekDay: weekDay.key,
            id: pref.id,
          });
        }
      }

      await availabilityPreferenceApi.updatePreferences(
        this.profileId,
        bulkPreferences
      );

      await this.loadData();
      this.isLoading = false;
      await this.updateSnackbar({
        message: 'Die Verfügbarkeitspräferenzen wurden erfolgreich gespeichert',
      });
    },
    async setLastUpdated(preferences) {
      const lastUpdated = preferences.sort(
        (a, b) => a.updatedAt - b.updatedAt
      )?.[0];
      if (lastUpdated) {
        const updatedBy = await this.fetchUser(lastUpdated.updatedBy);

        this.lastUpdated = {
          updatedAt: formatTimeStampToLocale(lastUpdated.updatedAt),
          updatedBy,
        };
      }
    },
    async fetchUser(profileId) {
      try {
        const user = await iamApi.getUser(profileId);
        return `${user.givenName} ${user.familyName}`;
      } catch (e) {
        return 'Unbekannt';
      }
    },
    async saveAndLeave() {
      this.isUnsavedChangesModalOpen = false;
      await this.updatePreference();
    },
  },
};
</script>

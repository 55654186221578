<template>
  <n-dialog
    :is-open="isOpen"
    :subject="subject"
    :description="description"
    :extended="!!extraData"
  >
    <template #default>
      <template v-if="extraData.trim().split('\n').length > 1">
        <p v-for="item in extraData.trim().split('\n')" :key="item">
          {{ item }}
        </p>
      </template>
      <p v-else>{{ extraData }}</p>
    </template>
    <template #actions>
      <n-button
        variant="secondary"
        data-test="modal-cancel"
        @click="$emit('close')"
      >
        Abbrechen
      </n-button>
      <n-button
        variant="primary"
        class="mt-18 ml-auto"
        data-test="modal-confirm"
        @click="
          $emit('confirm', $route.meta.resourceName, $route.params.contactId)
        "
      >
        {{ $t('common.labels.delete') }}
      </n-button>
    </template>
  </n-dialog>
</template>

<script>
import { IMAGE_URLS } from '@/constants/images';

export default {
  name: 'ConfirmationModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    extraData: {
      type: String,
      default: '',
    },
  },
  emits: ['confirm', 'close'],
  computed: {
    imageUrl() {
      return IMAGE_URLS.TRASH;
    },
  },
};
</script>

<style scoped>
.modal-content {
  @apply overflow-y-auto text-center;
  max-height: 60vh;
}
</style>
